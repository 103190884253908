<template>
  <div class="receivable-dialog">
    <el-form
      ref="form"
      :model="formData"
      :rules="rules"
      label-width="120px"
      label-position="top"
      size="small"
    >
      <div class="items-wrap">
        <el-form-item label="所属大区" class="form-item1" prop="merchantCityId">
          <KeySelect
            :val.sync="formData.merchantCityId"
            :label.sync="formData.merchantCityName"
            class="fill-item"
            city
          />
        </el-form-item>

        <el-form-item label="收款商户机构" class="form-item1" prop="merchantInstitutionVals">
          <PayOptionsCascader
            :default-text="formData.merchantInstitutionName"
            class="fill-item"
            second-level
            :val="formData.merchantInstitutionVals"
            @change="payOptionsCascaderChange"
          />
        </el-form-item>

        <el-form-item label="收款商户名称" class="form-item1" prop="merchantName">
          <el-input
            v-model="formData.merchantName"
            class="fill-item"
            placeholder="请输入收款商户名称"
            :disabled="partDisabled"
          ></el-input>
        </el-form-item>

        <el-form-item label="收款商户账号" class="form-item1">
          <el-input
            v-model="formData.merchantAccount"
            class="fill-item"
            placeholder="请输入收款商户账号"
            :disabled="partDisabled"
          ></el-input>
        </el-form-item>

        <el-form-item label="收款商户负责人" class="form-item1" prop="merchantPersonChargeId">
          <KeySelect
            :val.sync="formData.merchantPersonChargeId"
            :label.sync="formData.merchantPersonCharge"
            class="fill-item"
            user
            :default-text="formData.merchantPersonCharge"
          />
        </el-form-item>

        <el-form-item label="收款商户登录后台地址" class="form-item2">
          <el-input
            v-model="formData.merchantBackgroundAddress"
            class="fill-item"
            placeholder="请输入登录后台地址"
          ></el-input>
        </el-form-item>
      </div>

      <div class="items-wrap">
        <el-form-item label="关联结算账户" class="form-item1" >
          <el-input
            v-model="formData.settleAccount"
            class="fill-item"
            placeholder="请输入关联结算账户"
          ></el-input>
        </el-form-item>

        <el-form-item label="结算账户所属机构" class="form-item1" prop="settleBackDictionaryId">
          <KeySelect
            :val.sync="formData.settleBackDictionaryId"
            :label.sync="formData.settleBackDictionaryName"
            req-key="store_tx_bank"
            class="fill-item"
          />
        </el-form-item>

        <el-form-item label="结算账户负责人" class="form-item1" prop="settlePersonChargeId">
          <KeySelect
            :val.sync="formData.settlePersonChargeId"
            :label.sync="formData.settlePersonCharge"
            class="fill-item"
            user
            :default-text="formData.settlePersonCharge"
          />
        </el-form-item>

        <el-form-item label="结算账户登录后台地址" class="form-item2">
          <el-input
            v-model="formData.settleBackgroundAddress"
            class="fill-item"
            placeholder="请输入登录后台地址"
          ></el-input>
        </el-form-item>
      </div>
    </el-form>

    <span class="footer">
      <el-button size="small" @click="close">取 消</el-button>
      <el-button type="primary" size="small" @click="submit">确 定</el-button>
    </span>
  </div>
</template>

<script>
import { saveMerchantManage } from '@/api/receiveAudit';

export default {
  props: {
    item: {
      type: Object,
      default: () => ({})
    },
    partDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    const checkBankNO = (rule, value, callback) => {
      const reg = /^([1-9]{1})(\d{15}|\d{16}|\d{18})$/;
      if (reg.test(value)) callback();
      else callback(new Error('银行卡号格式错误'));
    };

    return {
      formData: {
        merchantCityId: '',
        merchantCityName: '',
        merchantInstitutionVals: [],
        merchantInstitutionId: '',
        merchantName: '',
        merchantAccount: '',
        merchantPersonCharge: '',
        merchantPersonChargeId: '',
        merchantBackgroundAddress: '',
        settleAccount: '',
        settleBackDictionaryId: '',
        settleBackDictionaryName: '',
        settlePersonCharge: '',
        settlePersonChargeId: ''
      },
      rules: {
        merchantCityId: { required: true, message: '请选择所属大区', trigger: 'change' },
        merchantInstitutionVals: { required: true, message: '请选择收款机构', trigger: 'change' },
        merchantName: { required: true, message: '请输入收款商户名称', trigger: 'blur' },
        merchantAccount: { required: true, message: '请输入收款商户账号', trigger: 'blur' },
        merchantPersonChargeId: {
          required: true,
          message: '请选择收款商户负责人',
          trigger: 'change'
        },
        settleAccount: { required: true, validator: checkBankNO, trigger: 'blur' },
        settleBackDictionaryId: {
          required: true,
          message: '请选择结算账户所属机构',
          trigger: 'change'
        },
        settlePersonChargeId: { required: true, message: '请选择结算账户负责人', trigger: 'change' }
      }
    };
  },
  created() {
    if (Object.keys(this.item).length > 0) {
      this.formData = {
        ...this.item,
        merchantInstitutionVals: [
          this.item.merchantInstitutionDictionaryId,
          this.item.merchantInstitutionId
        ]
      };
    }
  },
  methods: {
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        saveMerchantManage({ data: this.formData })
          .then((res) => {
            if (!res) return;
            this.$message.success('操作成功');
            this.$emit('refresh');
            this.close();
          })
          .catch((err) => console.log(err));
      });
    },
    close() {
      this.$emit('close');
    },
    payOptionsCascaderChange(_, path) {
      this.formData.merchantInstitutionVals = path;
      this.formData.merchantInstitutionId = path.length > 1 ? path[1] : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.receivable-dialog {
  .items-wrap {
    white-space: normal;

    .fill-item {
      width: 100%;
    }

    .form-item1,
    .form-item2 {
      width: 32%;
      margin-right: 1%;
      display: inline-block;
    }

    .form-item2 {
      width: 47%;
    }
  }

  .footer {
    text-align: right;
    display: inline-block;
    width: 100%;
  }
}
</style>
