l
<template>
  <section v-loading="loading" class="pay-rules-wrapper">
    <div class="header">
      <el-tabs v-model="type" tab-position="left">
        <el-tab-pane label="收款商户配置" name="1">
          <div v-if="type == '1'" class="pay-rules-content">
            <ReceivableList />
          </div>
        </el-tab-pane>

        <el-tab-pane label="收款订单分账规则配置" name="2">
          <div class="pay-rules-content">
            <pay-form :pay-form="payForm" @get="getKeys" />
          </div>
        </el-tab-pane>

        <el-tab-pane label="加购订单分账规则配置" name="3">
          <div class="pay-rules-content">
            <additional-form :add-form="addForm" @get="getKeys" />
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
  </section>
</template>

<script>
import PayForm from './PayForm.vue';
import AdditionalForm from './AdditionalForm.vue';
import ReceivableList from './components/ReceivableList.vue';
import { getSplitAccountConfig } from '@/api/pay';

export default {
  name: 'PayRule',
  components: {
    // CommonTitle
    PayForm,
    AdditionalForm,
    ReceivableList
  },
  data() {
    return {
      type: '1',
      addForm: {},
      payForm: {},
      loading: false
    };
  },
  created() {
    this.getKeys();
  },
  methods: {
    async getKeys() {
      this.loading = true;
      const params = { data: '' };
      getSplitAccountConfig(params).then((res) => {
        this.loading = false;
        this.addForm = res.profitsharing_addition_store;
        this.payForm = res.profitsharing_order_store;
      });
    }
  }
};
</script>

<style scoped lang="less">
.pay-rules-wrapper {
  margin-top: 16px;
  background: #fff;
  .pay-rules-content {
    display: flex;
    justify-content: center;
    padding: 30px;
  }
}
</style>
