<template>
  <div class="receivable-list">
    <div class="search-bar">
      <el-button type="primary" size="small" class="btn" icon="el-icon-plus" @click="processItem({}, 1)"> 收款商户 </el-button>

      <el-button size="small" class="btn" @click="gotoPage({}, 'ReceiveAgencies', 1)"> 商户机构管理 </el-button>

      <span class="label">收款商户负责人：</span>
      <KeySelect :val.sync="search.merchantPersonChargeId" class="select" user />

      <span class="label">结算账户负责人：</span>
      <KeySelect :val.sync="search.settlePersonChargeId" class="select" user />

      <span class="label">状态：</span>
      <el-select v-model="search.merchantStatus" placeholder="请选择" size="small" class="select">
        <el-option v-for="item in statusOptions" :key="item.label" :label="item.label" :value="item.value"> </el-option>
      </el-select>

      <span class="label">所属大区：</span>
      <KeySelect :val.sync="search.merchantCityId" class="select" city />

      <el-button type="primary" size="small" class="btn" icon="el-icon-search" @click="searchList"> 查询 </el-button>

      <el-button size="small" class="btn" icon="el-icon-refresh-left" @click="resetSearch"> 重置 </el-button>
    </div>

    <el-table :data="tableData" style="width: 100%" class="table" border :header-cell-style="{ 'background-color': '#f5f7fa' }">
      <el-table-column prop="merchantNo" label="编号"> </el-table-column>
      <el-table-column prop="merchantName" label="收款商户名称"> </el-table-column>
      <el-table-column prop="merchantCityName" label="所属大区"> </el-table-column>
      <el-table-column prop="merchantAccount" label="收款商户账号"> </el-table-column>
      <el-table-column prop="merchantInstitutionName" label="收款商户机构"> </el-table-column>
      <el-table-column label="收款商户后台地址">
        <template slot-scope="scope">
          <el-button v-if="scope.row.merchantBackgroundAddress" type="text" @click="openTab(scope.row.merchantBackgroundAddress)"> 去登录 </el-button>

          <span v-else>/</span>
        </template>
      </el-table-column>

      <el-table-column prop="merchantPersonCharge" label="收款账户负责人" width="120"> </el-table-column>

      <el-table-column prop="settleAccount" label="关联结算账户" width="120"> </el-table-column>

      <el-table-column label="结算账户后台地址">
        <template slot-scope="scope">
          <el-button v-if="scope.row.settleBackgroundAddress" type="text" @click="openTab(scope.row.settleBackgroundAddress)"> 去登录 </el-button>

          <span v-else>/</span>
        </template>
      </el-table-column>

      <el-table-column prop="settlePersonCharge" label="结算账户负责人" width="120"> </el-table-column>

      <el-table-column label="收款记录" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="gotoPage(scope.row, 'ReceiveAuditList', 2)">
            {{ scope.row.collectMoneyNum || 0 }}
          </el-button>
        </template>
      </el-table-column>

      <el-table-column label="状态" width="100">
        <template slot-scope="scope">
          <el-tag :type="scope.row.merchantStatus == 1 ? 'success' : 'danger'">
            {{ scope.row.merchantStatus == 1 ? '启用' : '停用' }}
          </el-tag>
        </template>
      </el-table-column>

      <el-table-column prop="createUserName" label="创建人" width="120"> </el-table-column>
      <el-table-column prop="updateTime" label="最后更新时间" width="120"> </el-table-column>

      <el-table-column fixed="right" label="操作" width="160">
        <template slot-scope="scope">
          <el-button v-if="!scope.row.collectMoneyNum || scope.row.merchantStatus == 0" type="text" size="small" @click="processItem(scope.row, 2)"> 编辑 </el-button>

          <el-button type="text" size="small" @click="processItem(scope.row, 3)">
            {{ scope.row.merchantStatus == 1 ? '停用' : '启用' }}
          </el-button>

          <el-button v-if="!scope.row.collectMoneyNum" type="text" size="small" @click="processItem(scope.row, 4)"> 删除 </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="footer">
      <el-pagination background layout="total,prev,pager,next,sizes" :total="total" :page-sizes="[10, 50, 100, 200]" @size-change="pageSizeChange" @current-change="pageChange">
      </el-pagination>
    </div>

    <el-dialog :title="dialogTitle" :visible.sync="dialogVisible" width="700px" :close-on-click-modal="false" :close-on-press-escape="false" top="30px">
      <ReceivableDialog v-if="dialogVisible" :item="currentItem" :part-disabled="partDisabled" @refresh="getList" @close="dialogVisible = false" />
    </el-dialog>
  </div>
</template>

<script>
import { getMerchantManageList, stopEnableMerchantManage, deleteMerchantManage } from '@/api/receiveAudit'

import ReceivableDialog from './ReceivableDialog.vue'

export default {
  components: {
    ReceivableDialog,
  },
  data() {
    return {
      tableData: [],
      pageNumber: 1,
      pageSize: 10,
      total: 0,
      search: {
        merchantStatus: '',
        merchantCityId: '',
        merchantPersonChargeId: '',
        settlePersonChargeId: '',
        merchantInstitutionId: '',
      },
      statusOptions: [
        {
          value: '',
          label: '全部',
        },
        {
          value: 0,
          label: '停用',
        },
        {
          value: 1,
          label: '启用',
        },
      ],
      dialogVisible: false,
      dialogTitle: '收款商户',
      currentItem: {},
    }
  },
  computed: {
    searchCondition() {
      const obj = { ...this.search }

      return {
        data: obj,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize,
      }
    },
    partDisabled() {
      return Number(this.currentItem.merchantStatus) === 0 && this.currentItem.collectMoneyNum > 0
    },
  },
  mounted() {
    this.search.merchantInstitutionId = this.$route.query.merchantInstitutionId || ''
    this.getList()
  },
  methods: {
    processItem(row, type) {
      type = Number(type)
      if (type === 1 || type === 2) {
        this.dialogVisible = true
        this.dialogTitle = type === 1 ? '新增收款商户' : '编辑收款商户'
        this.currentItem = type === 1 ? {} : { ...row }
        return
      }

      if (type === 3) {
        this.$confirm(`确认是否${row.merchantStatus === 0 ? '启用' : '停用'}?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            stopEnableMerchantManage({ data: row.merchantId })
              .then((res) => {
                if (res) {
                  this.$message.success('操作成功')
                  this.getList()
                }
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err))

        return
      }

      if (type === 4) {
        this.$confirm(`确认是否删除?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteMerchantManage({ data: [row.merchantId] })
              .then((res) => {
                if (res) {
                  this.$message.success('操作成功')
                  this.getList()
                }
              })
              .catch((err) => console.log(err))
          })
          .catch((err) => console.log(err))
      }
    },
    resetSearch() {
      Object.keys(this.search).forEach((el) => {
        this.search[el] = ''
      })

      this.pageNumber = 1
      this.getList()
    },
    getList() {
      getMerchantManageList(this.searchCondition)
        .then((res) => {
          const { content, total } = res || {}
          this.tableData = content || []
          this.total = Number(total || '0')
        })
        .catch((err) => console.log(err))
    },
    pageSizeChange(val) {
      this.pageSize = val
      this.getList()
    },
    pageChange(val) {
      this.pageNumber = val
      this.getList()
    },
    searchList() {
      this.pageNumber = 1
      this.getList()
    },
    gotoPage(row, name, type) {
      console.log(row, name, type)
      type = Number(type)
      if (type === 1) this.$router.push({ name })
      if (type === 2) this.$router.push({ name, query: { merchantId: row.merchantId } })
    },
    openTab(url) {
      url && window.open(url.startsWith('http') ? url : 'https://' + url)
    },
  },
}
</script>

<style lang="scss" scoped>
.receivable-list {
  background-color: white;
  width: 100%;

  .search-bar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > :nth-child(n) {
      margin-top: 20px;
    }

    .label {
      font-size: 14px;
      margin-left: 15px;
    }

    .select {
      width: 200px;
      margin-right: 10px;
    }

    .input {
      width: 220px;
    }

    .date-picker {
      width: 360px;
    }

    .btn {
      margin-left: 15px;
    }
  }

  .table {
    margin-top: 20px;
  }

  .footer {
    display: flex;
    justify-content: flex-end;
    padding: 20px 0 0 0;
  }

  .dialog-footer {
    text-align: right;
  }
}
</style>
