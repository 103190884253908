<template>
  <el-form label-width="140px" class="form" :model="payForm" :rules="{}">
    <el-form-item label="平台分账比例：" prop="number">
      <el-input-number v-model="value" :controls="false" :precision="2" :min="0.0" :max="100"> </el-input-number>
      %</el-form-item
    >
    <el-form-item label="服务供应商分账比例：">
      <el-input v-model="storeNumber" placeholder="请输入" disabled> </el-input>
      %
    </el-form-item>
    <div class="tip">注：以上为收款订单支付金额分账比例，收款订单的服务订单完成服务后，系统将按照以上比例分账</div>
    <el-form-item label="">
      <!-- <el-button>取消</el-button> -->
      <el-button type="primary" @click="setConfig">确认</el-button>
    </el-form-item>
  </el-form>
</template>

<script>
import {
  // getSplitAccountConfig, // getSplitAccountList,
  setPaySplitAccountConfigAbout,
} from '@/api/pay'

export default {
  name: 'PayForm',
  props: {
    payForm: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      value: 0,
      storeNumber: 0,
    }
  },

  watch: {
    value(val) {
      this.storeNumber = (100 - (this.value || Number(this.value) === 0 ? this.value : 100)).toFixed(2)
    },
    payForm: {
      deep: true,
      handler(val) {
        this.storeNumber = val.dictionaryValue
        this.value = 100 - (this.storeNumber ? this.storeNumber : 100)
      },
    },
  },
  mounted() {},
  methods: {
    setConfig() {
      setPaySplitAccountConfigAbout({ data: this.storeNumber }).then(() => {
        this.$message({
          message: '操作成功',
          type: 'success',
        })
        this.$emit('get')
      })
    },
  },
}
</script>

<style scoped lang="less">
.form {
  width: 600px;
  .tip {
    text-align: center;
    font-size: 12px;
    padding: 30px;
    color: #e77453;
  }
  ::v-deep.el-input-number {
    width: 100%;
    .el-input__inner {
      text-align: left;
    }
  }
  ::v-deep .el-form-item__content {
    display: flex;
    .el-input,
    .el-input-number {
      margin-right: 8px;
    }
  }
}
</style>
